body {
  margin: 0;
  padding: 0;
}

.schoolDescr {
  font-family: "Athelas";
  font-weight: 400;
  font-size: 40px;
}

h2.headerSection {
  font-size: 32px;
  font-weight: 400;

  font-family: 'Zametka';
  color: #303C67;
}

@media (max-width: 700px) {
  h2.headerSection {
    font-size: 32px;
  }
}

@media (min-width: 900px) {

  .hide-desktop {
    display: none;
  }
}

@media (max-width: 900px) {
  .hide-mobile {
    display: none;
  }
}

@font-face {
  font-family: "Zametka";
  src: local("Zametka"), url("./assets/Zametka2.ttf") format("truetype");
}

@font-face {
  font-family: "Athelas";
  src: local("Athelas"), url("./assets/Athelas.ttf") format("truetype");
}


.global-wrapper {
  display: flex;
  justify-content: center;
}

.global-wrapper-content {
  max-width: 1440px;
}

.global-wrapper-colored {
  background-color: #D1E9F4;
}