.teachers {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.leftside {
    margin-left: 60px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}


.aboutSchool {
    margin-bottom: 46px;

}

.text-content {
    display: flex;
    flex-direction: column;
    text-align: center;
    color: #303C67;
    align-items: center;
}

.teachers-team {
    font-family: "Sunshine";
    font-weight: 400;

    font-size: 24px;
    max-width: 270px;
}

.teachers-cards {
    display: flex;
    align-items: center;
    margin-top: 70px;
}

.teachers-cards img {
    width: auto;
    height: 80px;
}

.teachers-cards_mediaQ img {
    width: auto;
    height: 120px;
}

.teachers-cards img#leftarrow {
    transform: rotate(180deg);
    cursor: pointer
}

.teachers-cards img#rightarrow {
    cursor: pointer
}

.teachers-cards_mediaQ img#leftarrow {
    transform: rotate(180deg);
}

.photo-content {
    font-family: "Athelas";
    position: relative;

}

.photo-card {
    position: relative;
}

.photo-card p {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    font-size: 24px;
    background-color: #D7EBF2;
    padding: 5px 10px;
}

.photo-card img {
    border: 3px solid #D7EBF2;
    height: 400px;
    width: auto;
    border-radius: 5px;
}

.descrip {
    position: absolute;
    padding: 0;
    margin: 0;
    bottom: 0;
    width: 100%;
    list-style-position: inside;

}

.descrip li {
    font-size: 18px;
    background-color: #D7EBF2;
    padding: 5px 10px;
    margin: 0;
}

.teachers-cards_mediaQ {
    display: none;
}

.animals {
    width: 400px;
    height: auto;
    margin-right: 200px;
}

@media (max-width:900px) {



    .teachers-team {
        z-index: 1;
        width: 100%;
        max-width: 300px;
        font-size: 24px;
    }

    .teachers-cards {
        z-index: 2;

    }

    .teachers {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
    }

    .teachers-cards {
        display: none;
    }

    .teachers-cards_mediaQ {
        display: flex;
        align-items: center;
    }

    .aboutSchool {
        font-size: 41px;
        margin-bottom: 8px;

    }

    .animals {
        max-width: 300px;
        margin-top: 18px;
        height: auto;
        margin-right: 0;
    }

    h2.headerSection {
        font-size: 30px;
        text-align: center;
        font-family: 'Zametka';
        max-width: 220px;
    }
}