.buttoms2 {
    font-family: "Sunshine";
    font-weight: 400;
    font-size: 40px;
    display: flex;
    align-items: center;
    flex-direction: row;
    white-space: nowrap;
}

.nav {
    background-color: #FFFFFF;
    color: #3277AD;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    z-index: 999;
    height: 80px;
    top: 0;
}

svg {
    width: 120px;
    height: auto;
    fill: #3277AD;
}

.nav .buttoms2 a {
    color: #3277AD;
    margin-left: 70px;
    font-size: 22px;
    text-decoration: none;
}

.nav a:hover {
    text-decoration: underline;
    color: #151832;
}

.nav a:first-child {
    margin-left: 0px;
}

.nav a:last-child {
    margin-right: 80px;
}

.nav .logo {
    margin: 10px 70px;

}

.nav .logo img {
    height: 75.5px;
    width: auto;
}



.nav .burger-menu {
    width: 20px;
    position: absolute;
    top: 0;
    left: 0;
}

.nav .burger-menu a {
    color: #91c8f3;
    margin-top: 20px;
    font-size: 24px;
    text-decoration: none;
}

@media (max-width:900px) {
    .buttoms2 {
        display: none;
    }

    .nav .logo img {
        margin-left: -84px;
    }

}

@media (min-width:900px) {
    .nav .burger-menu {
        display: none;
    }
}