.aboutSchoolIntro {
    color: #303C67;
    font-size: 58px;
    font-style: normal;
    font-weight: 400;
    font-family: "Zametka";

}

.paragraph {
    font-family: "Athelas";
}




.description {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-around;
}

.schoolMainPic {

    margin-left: 60px;
    margin-top: 2px;
}

.about {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.schoolDescr p {
    max-width: 620px;
    margin: 0px;

}


.schoolDescr {
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 53px;
    text-align: center;
    color: #151832;
}

.buttonIntro {
    padding: 15px 54px;
    background-color: #ED242A;
    color: #FAF4CB;
    border-radius: 18px;
    border: none;
    font-size: 20px;
    font-family: "Zametka";

    cursor: pointer;
    transition: all 0.1s;
    margin: 18px;
}


.buttonIntro:hover {
    background-color: #E10007;

}

.benefits {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    font-weight: 400;
    font-size: 24px;
    line-height: 42.2px;
    text-align: center;
    padding: 0 20px;
}

.benefits div {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: center;
    margin-right: 25px;
}

.benefits div:last-child {
    margin-right: 0;
}

.benefits div p {
    color: #303C67;
    font-family: 'Zametka';
}

.intro {
    width: auto;
    left: 0px;
    top: 846px;
    border-radius: 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 20px;

}


@media (max-width:900px) {
    .intro .hide-desktop {
        display: flex;
        justify-content: center;
    }

    .description {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column-reverse;
        justify-content: space-around;
    }

    .buttonIntro {
        margin: 24px;
        margin-top: 0;
    }

    .benefits {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: space-around;
    }

    .intro {
        background-size: auto;
        background-origin: border-box;
        background-size: 100%
    }

    .schoolMainPic {
        height: auto;
        max-width: 350px;
        margin-left: 0px;
        margin-top: 24px;
    }

    .picture {
        text-align: center;
    }

    .schoolDescr {
        font-size: 28px;
    }


    .nav .logo img {
        margin-right: 190px;
        height: 42px;
        width: auto;
    }

    .aboutSchoolIntro {
        color: #303C67;
        font-size: 36px;
        font-style: normal;
        font-weight: 400;
        font-family: "Zametka";
        margin: 32px;
        margin-bottom: 8px;
    }

    .schoolDescr p {
        max-width: 620px;
        margin: 10px;
        line-height: 26px;
        font-size: 26px;
        margin-bottom: -18px;
    }

    .benefits div {
        display: flex;
        flex-direction: row;
        align-items: center;
        align-content: center;
        justify-content: center;

        margin-right: 0;
    }

    .benefits img {
        width: 80px;
        height: auto;
    }

    .benefits p {
        font-size: 18px;
    }



    .benefits div p {
        color: #303C67;
        font-family: 'Zametka';
        font-size: 14px;
        max-width: 195px;
        margin-left: 8px;
        line-height: 24px;
    }

    .benefits div img {
        height: auto;
        width: 45px;
    }
}

@media (max-width:400px) {
    .schoolMainPic {
        max-width: 250px;
    }
}