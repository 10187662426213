.carousel-root {
    margin: 12px 18px;
    max-width: 400px;
}

.carousel.carousel-slider {
    display: flex;
    flex-direction: column-reverse;
}

.carousel.carousel-slider .control-dots {
    position: relative;
}

.carousel.carousel-slider .control-dots li {
    opacity: 1;
    border: none;
    border-radius: 2pt;
    box-shadow: 0 0 0 1pt grey;
    outline: none;
    transition: .1s;
    /* border-radius: 100%;
    background-color: white;
    outline: solid 1px rgb(35, 48, 95); */
}

.carousel.carousel-slider .control-dots li.dot.selected {
    background-color: rgb(35, 48, 95);
}

.carousel li.slide p.legend {
    top: -22px;
    right: 0;
    left: auto;
    background-color: #D7EBF2;
    padding: 5px 10px;
    opacity: 1;
    color: rgb(48, 60, 103);
    font-size: 22px;
    width: fit-content;
    height: fit-content;
    border-radius: 2px;
}

.carousel .control-prev.control-arrow:before {
    border-right: 18px solid white;
}

.carousel .control-next.control-arrow:before {
    border-left: 18px solid white;
}

.carousel.carousel-slider button.control-arrow.control-prev {
    opacity: 1;
    /* background: rgba(0, 0, 0, 0.1); */
}

.carousel.carousel-slider button.control-arrow.control-next {
    /* background: rgba(0, 0, 0, 0.1); */
    opacity: 1;
}

.carousel.carousel-slider button.control-arrow.control-prev:hover {
    background: rgba(0, 0, 0, 0.3);
}

.carousel.carousel-slider button.control-arrow.control-next:hover {
    background: rgba(0, 0, 0, 0.3);
}


ul.control-dots {
    position: relative;
}

@media (min-width: 900px) {
    .carousel-root {
        display: none;
    }

}