.reviews {
    display: flex;
    flex-direction: column;
}

.reviews .header-reviews h2 {
    max-width: 100%;
}

.content-reviews {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-end;


}

.arrow_and_text img {
    width: auto;
    height: 80px;
    cursor: pointer;
    transition: all 0.1s;
    margin: 18px;
}

.arrow_and_text img#leftarrow {
    transform: rotate(180deg);

}

#rightarrow {
    width: auto;
    height: 80px;
}

.reviews-cards p {
    font-family: "Athelas";
    font-size: 22px;
    text-align: center
}

.reviews-cards {
    font-family: 'Zametka';
    display: flex;
    text-align: center;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 440px;


}

.review-carousel {
    display: none;
}

.reviews-cards.review-carousel {
    min-height: auto;
    width: fit-content;
}

.reviews-cards.review-carousel li.slide {
    max-width: 17vw;
}

.header-reviews h2 {
    font-family: 'Zametka';
    text-align: center
}

.reviews-cards {
    border: 3px solid #91C2D9;
    width: 510px;
    border-radius: 5px;
    color: #303C67;
    margin-bottom: 70px;
}

.arrow_and_text {
    display: flex;
    align-items: center;
}

.reviews .pictures {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.reviews-heart-red {
    top: -80px;
    left: -10px;
    width: 70px;
    height: auto;
}

.reviews-heart-violet {
    width: 130px;
    height: auto;
    left: 80px;
    top: -184px;
}

.reviews-heart-green {
    top: -80px;
    right: -10px;
    width: 110px;
    height: auto;
}

.seal {
    height: 300px;
    width: 300px;
}

.pictures .hearts img {
    position: absolute;
}

.reviews-cards p {
    margin: 15px;
}

@media (max-width:900px) {
    .content-reviews {
        display: none;
    }

    .seal {
        display: none;
    }

    .hearts {
        display: none;
    }

    .feedback-heart-blue {
        display: none;
    }



    .arrow_and_text img {
        width: auto;
        height: 80px;
        cursor: pointer;
        transition: all 0.1s;
        margin: 18px;
        height: 60px;
    }

    #rightarrow {
        width: auto;
        height: 40px;
    }

    .arrow_and_text img#leftarrow {
        transform: rotate(180deg);
        height: 40px;
    }

    .reviews-cards {
        border: 3px solid #91C2D9;
        width: 100%;
        max-width: 400px;
        border-radius: 5px;
        color: #303C67;
        margin-bottom: 70px;
    }

    .review-carousel {
        display: block;
    }
}