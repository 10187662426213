.etapu {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
}

.etapyLeft {
    font-size: 20px;

    display: flex;
    flex-direction: column;
    justify-content: center;

}

@media (max-width: 700px) {
    .etapyLeft {
        padding: 0;
        align-items: center;
    }
}

.stages {
    display: flex;
    flex-direction: column;

    align-items: flex-start
}




.stages div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 63%;
    height: auto;
    align-items: center;
    width: -webkit-fill-available;
}

.stages div p {
    background-color: #FFFFFF;
    padding-left: 50px;
    border-radius: 10px;
    border: 0;
    padding: 12px 12px;
    font-family: "Athelas";
    font-size: 18px;
    font-weight: 400;
    margin: 7px;
    width: 100%;
}

.stages div img {
    width: auto;
    height: 70px;

}


.stepsRight {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.heartRed {
    margin-left: 25px;
    margin-top: 40px;
    top: 45px;
    left: 45px;
    max-width: 60%;
    height: auto;
    width: 135px;

}







.heartGreen {
    position: relative;
    top: -65px;
    right: 323px;
    max-width: 50%;
    margin-left: 60px;
    margin-top: 2px;
    height: auto;
    width: 117px;

}

.heartViolet {
    position: relative;
    top: -50px;
    right: 165px;
    max-width: 300%;
    margin-left: 60px;
    margin-top: 2px;
    height: auto;
    width: 240px;
}

@media (max-width:900px) {
    .stepsRight {
        display: none;
    }

    .stages div {
        max-width: 100%;
    }

}