.feedback {
    width: auto;
    left: 0px;
    top: 846px;
    border-radius: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;

}

.feedback .success {
    background-color: #388e3c;
    color: white;
    padding: 8px 12px;
    border-radius: 4px;
}


.feedback .error {
    background-color: #e57373;
    color: white;
    padding: 8px 12px;
    border-radius: 4px;
}

.content-feedback {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
}

.userdata .buttonIntro {
    margin-top: 24px;
}

.userdata h3 {
    font-family: 'Zametka';
}

.userdata {
    border: 3px solid white;
    height: 410px;
    width: 510px;
    border-radius: 40px;
    color: #303C67;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 80px;
}

input {
    border: 3px solid #91C2D9;
    border-radius: 15px;
    height: 41px;
    width: 400px;
    font-size: 20px;
    margin-bottom: 10px;
    font-family: "Athelas";
    text-indent: 5px;
}

textarea {
    font-family: "Athelas";
    text-indent: 5px;
}

.feedback-heart-green {
    position: absolute;
    right: 800px;
    max-width: 40%;
    bottom: 20px;

}

.loader {
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #3498db;
    width: 120px;
    height: 120px;
    -webkit-animation: spin 2s linear infinite;
    /* Safari */
    animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.feedback-heart-blue {
    position: absolute;
    right: -200px;
    max-width: 40%;
    top: -85px;
}

.comments {
    border: 3px solid #91C2D9;
    border-radius: 15px;
    height: 150px;
    width: 400px;
    font-size: 20px;
}

input:focus {
    outline: none;
    border-color: #363d7a;
}

textarea:focus {
    outline: none;
    border-color: #363d7a;
}

@media (max-width:900px) {
    .userdata h3 {
        font-family: 'Zametka';
        font-size: 13px;
    }

    input {
        border: 3px solid #91C2D9;
        border-radius: 15px;
        height: 41px;
        width: 325px;
        font-size: 20px;
        margin-bottom: 10px;
        font-family: "Athelas";
        text-indent: 5px;
    }

    .feedback-heart-green {
        display: none;

    }

    .feedback-heart-blue {
        display: none;
    }

    .userdata {
        width: 360px;
        margin-bottom: 24px;
        margin-bottom: 60px;
    }

    textarea.comments {
        width: 325px;
    }
}