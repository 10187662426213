.faq {}

.faq-content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
}

.faq-content .faq-img {
    width: auto;
    height: 300px;
    margin-right: 24px;
}

.faq-questions {
    margin-bottom: 24px;
}

.faq-questions .accordion {
    margin-bottom: 8px;
}

@media (max-width: 1045px) {
    .faq-content {
        display: flex;
        justify-content: center;
    }
}

.accordion {
    max-width: 700px;
    border: 4px solid white;
    border-radius: 20px;
    padding: 12px;
    overflow: hidden;
    width: 500px;
    color: #303C67;
}

.accordion .fixed-accordion-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.accordion img.faq-toggle-icon {
    width: 57px;
    height: 57px;
    transform: rotate(270deg);
    transition-property: transform;
    transition-duration: 300ms;
    cursor: pointer;
}

.accordion img.faq-toggle-icon.accordion-arrow-toggled {
    transform: rotate(90deg);
}

.accordion .accordion-header {
    font-family: 'Zametka';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    margin: 0;
}

.accordion .toggle-accordion-section {
    max-height: 0;
    transition-property: max-height;
    transition-duration: 200ms;
}

.accordion .toggle-accordion-section p {
    margin: 0;
    padding-top: 12px;
}

.accordion .toggle-accordion-section.accordion-grow {
    transition-duration: 500ms;
    max-height: 250px;
}

.accordion .question1 {
    font-size: 20px;
    font-family: 'Zametka';
}

.headerSection {
    font-size: 45px;
    font-family: 'Zametka';
    color: #303C67;

}

@media (max-width:900px) {

    .faq-header-wrapper {
        display: flex;
        justify-content: center;
    }

    h2.headerSection {
        font-size: 25px;
        text-align: center;
        font-family: 'Zametka';
        color: #303C67;
        max-width: 300px;
    }


    .faq-content {
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
    }

    .faq-content .faq-img {
        width: 300px;
        height: auto;
        margin: auto;
    }

    .accordion {
        max-width: 612px;
        width: auto;
        border: 4px solid white;
        border-radius: 20px;
        padding: 12px;
        overflow: hidden;
        margin: 0;
    }
}