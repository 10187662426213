.prices {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.headerPrices {
    margin: 0;
    margin-top: 65px;
    display: flex;
}

.typeOfLesson {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 18px;
    margin-bottom: 18px;

}

.button-group {
    display: flex;
    border-radius: 20px;
    background-color: #D9D9D9;
    font-family: 'Zametka';
    margin-bottom: 18px;
    cursor: pointer;
    color: #303C67;
}



.button-group div {
    margin: 2px;
    font-size: 22px;
    font-weight: 400;
    padding: 8px;
    cursor: pointer;
    border-radius: 20px;
    cursor: pointer;
}

.button-group .selected-button {
    background-color: #FFF;
    cursor: pointer;
}


.packages {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
}

.packages div {
    display: flex;
    flex-direction: column;
    width: auto;
    border-radius: 1.5%;
    padding: 20px 16px;


}

.packages div div {
    display: flex;
    flex-direction: column;
}

.priceBen {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    font-family: Zametka;

}

.priceBen p {
    margin: 20px;
    color: #303C67;
}

.greenTitle {
    color: #006837;
}

.sessions p {
    color: #303C67;
}

.priceLine {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.p {
    color: #303C67;
}

.groupLessons {
    display: none;
}

.personalMain {
    display: none;
}

.timeTitles {
    display: flex;
    flex-direction: row;
}

.sessions {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}


.singleSession {
    display: flex;
    flex-direction: column;
    background-color: #D1E9F4;
    font-size: 50px;
    font-family: Zametka;
    z-index: 2;
    border-radius: 6%;
}

@media (max-width: 700px) {
    .singleSession {
        width: 100%;
        margin-top: 20px;
    }
}


.nicknamesGroup {
    display: flex;
    flex-direction: row;
}

.nicknameOne {
    display: flex;
    flex-direction: column;
    border-radius: 6%;
    margin: 16px;
    text-align: center;
    font-size: 12px;
}

.purple {
    color: #676C9A;
}


.changecollor {
    background-color: #A5F1CD;

}

.changeText {
    color: #006837;
    font-size: 22px;
    text-align: center;
}

.titleTime {
    text-align: center;
    font-size: 30px;
    margin: 10px;
    color: #303C67;
}

.nicknamesAll {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly
}


.nicknamesAll div {
    background-color: white;
    width: 210px;
}

.nicknameOne button {
    padding: 7px;
    color: #000000;
    border-radius: 9px;
    border-color: #000000;
    border: 1px solid #3277AD;
    font-size: 12px;
    text-align: center;
    font-family: "Zametka";
    cursor: pointer;
    margin: 18px;

    background-color: #D1E9F4;
}

.specialBenifit {
    background-color: rgba(0, 104, 55, 0.2);
    margin: 12px;
    color: rgba(0, 104, 55, 0.5);
    position: absolute;
    margin-left: 42px;
    margin-top: 97px;
}

.makeRow {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center
}

.spaceBetween {
    justify-content: space-between;
}

.digit {
    font-size: 18px;
}

.line {
    height: 2px;
    width: auto;
    background-color: #D1E9F4;
    margin: 0px 12px;
}


.topPicture {
    position: absolute;
    margin-left: 397px;
    margin-top: -104px;
    height: 130px;
    width: auto;
    z-index: -1;
}


.walrus {
    position: absolute;
    margin-left: -384px;
    margin-top: 267px;


    height: 55px;
    width: auto;
}

#heartOnPrice {
    height: 55px;
    width: auto;
    position: inherit;
}


#pointsForGroup {

    font-family: Zametka;
    font-size: 18px;
    margin: 40px;
    text-align: center;

}

#groupMain {
    width: 600px;
    justify-content: space-between;
}

@media (max-width: 700px) {
    #groupMain {
        width: 60%;
    }

}

@media (max-width:900px) {
    #groupMain {
        display: flex;
        flex-direction: column;
    }

    .singleSession {
        width: 330px;
    }

    .sessions {
        display: flex;
        flex-direction: column
    }

    .nicknamesAll {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .h2 {
        font-size: 35px;
    }

    .priceBen {
        display: flex;
        flex-direction: column
    }

    .priceBen p {
        color: #303C67;
    }

    .topPicture {
        display: none;
    }

    .walrus {
        display: none;
    }
}